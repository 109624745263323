import { Accordion } from "react-bootstrap";
import { Driver, pointCalculationMethod, raceResultOverview } from "../Models";
import Flag from "react-world-flags";
import styled from "styled-components";
import { RaceResultOverview } from "./RaceResultOverview";

export interface IProps {
  raceResults: raceResultOverview[] | undefined;
  getDriverByNumber: (
    driverNumber: number | undefined | null
  ) => Driver | undefined;
  pointCalculationMethod: pointCalculationMethod | undefined;
}

export const PastRaceResults = ({ raceResults, getDriverByNumber, pointCalculationMethod }: IProps) => {
  const StyledAccordion = styled(Accordion)`
    font-family: "Formula1";
  `;

  const StyledBody = styled(Accordion.Body)`
    padding: 0;
  `;

  const RaceAccordionItem = (props: {
    race: raceResultOverview;
    pointCalculationMethod: pointCalculationMethod | undefined;
    eventKey: string;
  }) => {
    return (
      <Accordion.Item eventKey={props.eventKey}>
        <Accordion.Header>
          <Flag code={props.race.countryCode} height={14} />{" "}
          {props.race.raceName}
        </Accordion.Header>
        <StyledBody>
          <RaceResultOverview
            raceResult={props.race}
            getDriverByNumber={getDriverByNumber}
            pointCalculationMethod={props.pointCalculationMethod}
          />
        </StyledBody>
      </Accordion.Item>
    );
  };

  return (
    <StyledAccordion>
      {raceResults &&
        raceResults.sort().map((raceResult: raceResultOverview, index) => {
          const id = `item-${index}`;
          return <RaceAccordionItem key={id} race={raceResult} eventKey={id} pointCalculationMethod={pointCalculationMethod} />;
        })}
    </StyledAccordion>
  );
};
