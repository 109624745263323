import { Button, Modal } from "react-bootstrap";
import { Driver, PredictionPosition } from "../Models";
import styled from "styled-components";
import { useEffect, useState } from "react";

export interface SelectionRequest {
  position: PredictionPosition;
  selectedDriver: number | undefined | null;
}

export interface IProps {
  drivers: Driver[];
  selectionRequest: SelectionRequest | undefined;
  onDriverSelected: (
    selectedDriver: Driver | undefined,
    position: PredictionPosition
  ) => void;
}

export const DriversKeyboard = ({
  drivers,
  selectionRequest,
  onDriverSelected,
}: IProps) => {
  const [show, setShow] = useState<boolean>();

  useEffect(() => {
    if (selectionRequest !== undefined) {
      setShow(true);
    }
  }, [selectionRequest]);

  const onButtonPress = (driverId: string | undefined) => {
    if (selectionRequest !== undefined) {
      if (driverId === undefined) {
        onDriverSelected(undefined, selectionRequest.position);
      } else {
        const driver = drivers.find((driver) => driver.driverId === driverId);
        if (driver !== undefined)
          onDriverSelected(driver, selectionRequest.position);
      }
      setShow(false);
    }
  };

  const getPositionLabel = (position: PredictionPosition) => {
    switch (position) {
      case PredictionPosition.pos1:
        return "1st";
      case PredictionPosition.pos2:
        return "2nd";
      case PredictionPosition.pos3:
        return "3rd";
      case PredictionPosition.pos4:
        return "4th";
      case PredictionPosition.pos5:
        return "5th";
      case PredictionPosition.fastestLap:
        return "fastest lap";
    }
  };

  const StyledModal = styled(Modal)`
    font-family: "Formula1";
  `;

  const StyledTitle = styled(Modal.Title)`
    font-size: 14px;
  `;

  const StyledModalBody = styled(Modal.Body)`
    padding: 4px;
    background-color: #fff;
  `;

  const Keyboard = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(5, auto);
    gap: 3px;
    margin: 10px 0 0 0;
  `;

  const KeyboardButton = styled.div<{ teamColor: string; isSelected: boolean }>`
    width: 100%:
    height: 100%;
    padding: 4px;
    background-color: ${(props) => (props.isSelected ? "#eee" : "#fff")};
    border-radius: 4px;
    border: 1px solid ${(props) =>
      props.isSelected ? props.teamColor : "#ccc"};
    display: grid;
    grid-template-rows: [image] auto [name] auto;
    gap: 0;
    cursor: pointer;
    &:hover {
      background-color: #eee;
      border-color: ${(props) => props.teamColor};
    }
  `;

  const DriverImageContainer = styled.div`
    text-align: center;
    margin: 0 auto;
    height: 60px;
  `;
  const DriverName = styled.div<{ teamColor: string }>`
    border-radius: 6px 0 0 0;
    border-top: 3px solid ${(props) => props.teamColor};
    border-left: 3px solid ${(props) => props.teamColor};
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #111;
  `;

  const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  `;

  return (
    <StyledModal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => setShow(false)}
      animation={false}
    >
      <Modal.Header closeButton>
        <StyledTitle id="contained-modal-title-vcenter">
          Select driver for{" "}
          <strong>
            {selectionRequest?.position
              ? getPositionLabel(selectionRequest.position)
              : ""}
          </strong>
        </StyledTitle>
      </Modal.Header>
      <StyledModalBody>
        <Keyboard>
          {drivers &&
            drivers
              .filter((driver: Driver) => driver.isParticipating)
              .sort((a, b) => a.team.name.localeCompare(b.team.name))
              .map((driver: Driver, index) => {
                const isSelected =
                  selectionRequest?.selectedDriver === driver.number;
                return (
                  <KeyboardButton
                    teamColor={driver.team.color}
                    key={`item-${index}`}
                    onClick={() => onButtonPress(driver.driverId)}
                    isSelected={isSelected}
                  >
                    <DriverName teamColor={driver.team.color}>
                      {driver.abbreviation}
                    </DriverName>
                    <DriverImageContainer>
                      <img
                        src={`/drivers/${driver.abbreviation.toLowerCase()}.png`}
                        style={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                        }}
                        alt={driver.abbreviation}
                      />
                    </DriverImageContainer>
                  </KeyboardButton>
                );
              })}
        </Keyboard>
        <ButtonContainer>
          <Button
            onClick={() => onButtonPress(undefined)}
            variant="outline-danger"
            size="sm"
          >
            Clear
          </Button>
        </ButtonContainer>
      </StyledModalBody>
    </StyledModal>
  );
};
