import { useEffect, useState } from "react";
import {
  Driver,
  pool,
  race,
  raceResultOverview,
  userStanding,
  poolOverview,
  userWithPools,
  racePrediction,
  raceParticipation,
  racePrediction2,
} from "../Models";
import { Service } from "../Service";
import { NavBar2 } from "../Components/NavBar2";
import { PageLoadingPlaceholder2 } from "../Components/PageLoadingPlaceholder2";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { WeekendCard } from "../Components/WeekendCard";
import {
  IControlPredictionEdit,
  RacePredictionModalV2,
} from "../Components/RacePredictionModalV2";
import { PastRaceResults } from "../Components/PastRaceResults";
import styled from "styled-components";
import { useRaceWeekend } from "../Hooks/UseRaceWeekend";
import { StandingsList } from "../Components/StandingsList";
import { UpcommingRaceCard } from "../Components/UpcomingRaceCard";
import { useNextRace } from "../Hooks/UseNextRace";
import { RaceCalendarModal } from "../Components/RaceCalendarModal";
import { RaceResultOverview } from "../Components/RaceResultOverview";
import Flag from "react-world-flags";
import PodiumV2 from "../Components/PodiumV2";

enum PageState {
  Loading,
  Done,
  NotFound,
  Error,
}

export const PoolPage = ({ match }: any) => {
  const {
    params: { poolSlug },
  } = match;

  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [user, setUser] = useState<userWithPools>();
  const [pool, setPool] = useState<pool>();
  const [drivers, setDrivers] = useState<Driver[]>();
  const [races, setRaces] = useState<race[]>();
  const [standings, setStandings] = useState<userStanding[]>();
  const [raceResults, setRaceResults] = useState<raceResultOverview[]>();
  const [racePredictions, setRacePredictions] = useState<racePrediction[]>();
  const [raceParticipation, setRaceParticipation] =
    useState<raceParticipation[]>();

  const [
    raceWeekend,
    raceWeekendPrediction,
    raceWeekendParticipation,
    finishedRace,
  ] = useRaceWeekend(races, racePredictions, raceParticipation, raceResults);
  const [nextRace] = useNextRace(races);
  const [predictionEditControl, setPredictionEditControl] =
    useState<IControlPredictionEdit>();

  const [showEditRacePrediction, setShowEditRacePrediction] =
    useState<boolean>(false);
  const [showRaceCalendar, setShowRaceCalendar] = useState<boolean>(false);

  useEffect(() => {
    if (poolSlug) {
      Service.fetchPoolOverview(poolSlug).then((response: Response) => {
        if (response.ok) {
          response.json().then((poolOverview: poolOverview) => {
            setUser(poolOverview.user);
            setPool(poolOverview.pool);
            setDrivers(poolOverview.drivers);
            setRaces(poolOverview.races);
            setStandings(poolOverview.standing);
            setRaceResults(poolOverview.raceResults);
            setRacePredictions(poolOverview.racePredictions);
            setRaceParticipation(poolOverview.raceParticipation);
            setPageState(PageState.Done);
          });
        } else if (response.status === 404) {
          setPageState(PageState.NotFound);
        } else if (response.status === 400) {
          response.text().then((bodyText: string) => {
            setErrorMessage(bodyText);
          });
          setPageState(PageState.Error);
        }
      });
    }
    return () => {};
  }, [poolSlug]);

  const handlePredictionChange = (updatedPrediction: racePrediction) => {
    if (racePredictions !== undefined) {
      const canUpdateExisting = racePredictions.find(
        (prediction) => prediction.round === updatedPrediction.round
      );
      if (canUpdateExisting) {
        setRacePredictions((predictions) =>
          predictions?.map((prediction) => {
            if (prediction.round === updatedPrediction.round)
              return updatedPrediction;
            return prediction;
          })
        );
      } else {
        setRacePredictions((currentRacePredictions) => {
          if (currentRacePredictions)
            return [...currentRacePredictions, updatedPrediction];
          return [updatedPrediction];
        });
      }
    }
  };

  const openEditPredictionModal = () => {
    const predictionSourceCopy: racePrediction2 = {
      pos1: raceWeekendPrediction?.pos1 || undefined,
      pos2: raceWeekendPrediction?.pos2 || undefined,
      pos3: raceWeekendPrediction?.pos3 || undefined,
      pos4: raceWeekendPrediction?.pos4 || undefined,
      pos5: raceWeekendPrediction?.pos5 || undefined,
      fastestLap: raceWeekendPrediction?.fastestLap || undefined,
    };

    const editControlCopy: IControlPredictionEdit = {
      predictionSource: predictionSourceCopy,
    };

    setPredictionEditControl(editControlCopy);
    setShowEditRacePrediction(true);
  };

  const MobileView = styled.div`
    max-width: 375px;
    margin: 0 auto;
    padding: 0 0 60px 0;
  `;

  const SmallHeader = styled.h4`
    margin-top: 20px;
    font-family: "Formula1";
    font-size: 1em;
    color: #444;
  `;

  const getDriverByNumber = (
    driverNumber: number | undefined | null
  ): Driver | undefined => {
    return driverNumber !== undefined || driverNumber !== null
      ? drivers?.find((driver: Driver) => driver.number === driverNumber)
      : undefined;
  };

  const MainPage = () => {
    return (
      <MobileView>
        {raceWeekend && finishedRace === undefined && (
          <WeekendCard
            race={raceWeekend}
            prediction={raceWeekendPrediction}
            participation={raceWeekendParticipation}
            onClickEdit={() => openEditPredictionModal()}
            getDriverByNumber={getDriverByNumber}
          />
        )}

        {raceWeekend && finishedRace && drivers && (
          <>
            <SmallHeader>
              <Flag code={raceWeekend?.countryCode} height={14} />{" "}
              {raceWeekend?.shortName} Race Results
            </SmallHeader>
            <PodiumV2 raceResult={finishedRace} />
            <RaceResultOverview
              raceResult={finishedRace}
              getDriverByNumber={getDriverByNumber}
              pointCalculationMethod={pool?.pointCalculationMethod}
            />
          </>
        )}

        <SmallHeader>Standings</SmallHeader>
        <StandingsList standings={standings} currentUser={user} />

        {nextRace && (
          <UpcommingRaceCard
            nextRace={nextRace}
            onShowFullSchedule={() => setShowRaceCalendar(true)}
          />
        )}

        <SmallHeader>Past Races</SmallHeader>
        <PastRaceResults
          raceResults={raceResults}
          getDriverByNumber={getDriverByNumber}
          pointCalculationMethod={pool?.pointCalculationMethod}
        />

        <RacePredictionModalV2
          show={showEditRacePrediction}
          onHide={() => setShowEditRacePrediction(false)}
          user={user}
          race={raceWeekend}
          drivers={drivers}
          control={predictionEditControl}
          pool={pool}
          onPredictionChanged={handlePredictionChange}
        />

        <RaceCalendarModal
          races={races}
          show={showRaceCalendar}
          onHide={() => setShowRaceCalendar(false)}
        />
      </MobileView>
    );
  };

  const NotFoundMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Pool not found</h2>
          <p>
            No pool with name "{poolSlug}" was found.{" "}
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  const ErrorMessage = () => {
    return (
      <Row className="justify-content-md-center">
        <Col lg={4} style={{ margin: "40px" }}>
          <h2>Oops</h2>
          <p>{errorMessage}</p>
          <p>
            <Link to="/">go back to home</Link>
          </p>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {pageState === PageState.Loading ? (
        <PageLoadingPlaceholder2 />
      ) : (
        <>
          <NavBar2 currentPool={pool} user={user} />
          <Container fluid>
            <Row className="justify-content-md-center">
              <Col>
                {pageState === PageState.Done && <MainPage />}
                {pageState === PageState.NotFound && <NotFoundMessage />}
                {pageState === PageState.Error && <ErrorMessage />}
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};
