import styled from "styled-components";
import {
  Driver,
  PredictionVerdict,
  pointCalculationMethod,
  predictionResult,
  racePredictionResult,
  raceResultOverview,
} from "../Models";
import { ListGroup } from "react-bootstrap";
import { StopwatchFill } from "react-bootstrap-icons";

enum Position {
  First,
  Second,
  Third,
  Fourth,
  Fifth,
  Sixth,
  Seventh,
  FastestLap,
}

export interface IProps {
  getDriverByNumber: (
    driverNumber: number | undefined | null
  ) => Driver | undefined;
  raceResult: raceResultOverview;
  pointCalculationMethod: pointCalculationMethod | undefined;
}

export const RaceResultOverview = ({
  getDriverByNumber,
  raceResult,
  pointCalculationMethod
}: IProps) => {
  const StyledRaceResultOverview = styled(ListGroup)`
    font-family: "Formula1";
  `;

  const StyledHeader = styled(ListGroup.Item)`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    padding: 0;
    padding: 10px 0 0 0;
  `;

  const StyledHeaderPosition = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-content: center;
    align-items: center;
  `;

  const StyledPosition = styled.div`
    text-align: center;
    font-size: 16px;
  `;

  const OrdinalIndicator = styled.span`
    font-size: 10px;
  `;

  const StyledDriver = styled.div<{ teamColor: string }>`
    border-left: 4px solid ${(props) => props.teamColor};
    font-weight: bold;
    line-height: 1;
    padding: 0 0 0 3px;
    font-size: 12px;
  `;

  const DriverPredictionResult = styled.div<{ verdict: PredictionVerdict }>`
    font-weight: normal;
    line-height: 1;
    padding: 0 0 3px 0;
    width: 40px;
    font-size: 12px;
    text-align: center;

    ${({ verdict }) => {
      switch (verdict) {
        case PredictionVerdict.Exact:
          return `
            color: #000;
          `;
        case PredictionVerdict.Near:
          return `
            color: #000;
          `;
        case PredictionVerdict.Close:
          return `
            color: #000;
          `;
        case PredictionVerdict.Wrong:
        default:
          return `
            color: #999;
          `;
      }
    }};
  `;

  const DriverPredictionResultPoints = styled.div<{ verdict: PredictionVerdict }>`
    font-weight: bold;
    line-height: 1;
    padding: 2px 0;
    width: 40px;
    font-size: 10px;
    text-align: center;
    border-radius: 5px;

    ${({ verdict }) => {
      switch (verdict) {
        case PredictionVerdict.Exact:
          return `
            background-color: #990099;
            color: #ffffff;
          `;
        case PredictionVerdict.Near:
          return `
            background-color: #2caa1e;
            color: #ffffff;
          `;
        case PredictionVerdict.Close:
          return `
            background-color: #dedc30;;
            color: #000000;
          `;
        case PredictionVerdict.Wrong:
        default:
          return `
            background-color: #ccc;
            color: #000;
          `;
      }
    }};
  `;

  const StyledPredictionResultItem = styled(ListGroup.Item)`
    display: grid;
    grid-template-columns: [MainSection] 6fr [PointsSection] 1fr;
    gap 0;
    padding: 5px 0 5px 0;
  `;

  const MainSection = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap 0;
  `;

  const PointsSection = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
  `;

  const NameSection = styled.div`
    font-size: 12px;
    font-weight: bold;
    padding: 5px 0 0 10px;
  `;

  const ResultSection = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap 0;
    justify-content: center;
    align-items: center;
  `;

  const StyledPredictionResult = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
  `;

  const StyledPoints = styled.div`
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  `;

  const PositionLabel = (props: { position: Position }) => {
    const label = (number: number, ordinalIndicator: string) => {
      return (
        <StyledPosition>
          {number}
          <OrdinalIndicator>{ordinalIndicator}</OrdinalIndicator>
        </StyledPosition>
      );
    };

    switch (props.position) {
      case Position.First:
        return label(1, "st");
      case Position.Second:
        return label(2, "nd");
      case Position.Third:
        return label(3, "rd");
      case Position.Fourth:
        return label(4, "th");
      case Position.Fifth:
        return label(5, "th");
      case Position.Sixth:
        return label(6, "th");
      case Position.Seventh:
        return label(7, "th");
      case Position.FastestLap:
        return (
          <StyledPosition>
            <StopwatchFill />
          </StyledPosition>
        );
    }
  };

  const HeaderPosition = (props: {
    position: Position;
    driverNumber: number | undefined | null;
  }) => {
    const driver = getDriverByNumber(props.driverNumber);

    if (driver === undefined)
      return <StyledHeaderPosition></StyledHeaderPosition>;

    return (
      <StyledHeaderPosition>
        <PositionLabel position={props.position} />
        <StyledDriver teamColor={driver.team.color}>
          {driver.abbreviation}
        </StyledDriver>
      </StyledHeaderPosition>
    );
  };

  const PredictionResult = (props: {
    position: Position;
    predictionResult: predictionResult;
  }) => {
    const driver = getDriverByNumber(props.predictionResult.predicted);

    if (driver === undefined)
      return <StyledHeaderPosition></StyledHeaderPosition>;

    return (
      <StyledPredictionResult>
        <DriverPredictionResult verdict={props.predictionResult.verdict}>
          {driver.abbreviation}
        </DriverPredictionResult>
        <DriverPredictionResultPoints verdict={props.predictionResult.verdict}>
          {props.predictionResult.points}
        </DriverPredictionResultPoints>  
      </StyledPredictionResult>
    );
  };

  const TotalPoints = (props: { points: number }) => {
    return (
      <>
        <StyledPoints>{props.points}</StyledPoints>
        <OrdinalIndicator>PTS</OrdinalIndicator>
      </>
    );
  };

  return (
    <StyledRaceResultOverview>
      <StyledHeader>
        <HeaderPosition
          position={Position.First}
          driverNumber={raceResult.result?.pos1}
        />
        <HeaderPosition
          position={Position.Second}
          driverNumber={raceResult.result?.pos2}
        />
        <HeaderPosition
          position={Position.Third}
          driverNumber={raceResult.result?.pos3}
        />
        <HeaderPosition
          position={Position.Fourth}
          driverNumber={raceResult.result?.pos4}
        />
        <HeaderPosition
          position={Position.Fifth}
          driverNumber={raceResult.result?.pos5}
        />
        {pointCalculationMethod && pointCalculationMethod !== "Method2025" &&
          <HeaderPosition
            position={Position.FastestLap}
            driverNumber={raceResult.result?.fastestLap}
          />
        }
        {pointCalculationMethod && pointCalculationMethod == "Method2025" &&
          <HeaderPosition
            position={Position.Sixth}
            driverNumber={raceResult.result?.pos6}
          />
        }
        {pointCalculationMethod && pointCalculationMethod == "Method2025" &&
          <HeaderPosition
            position={Position.Seventh}
            driverNumber={raceResult.result?.pos7}
          />
        }
      </StyledHeader>
      {raceResult.predictions
        ?.sort((a, b) => (a.totalPoints > b.totalPoints ? -1 : 1))
        .map((result: racePredictionResult, index) => {
          return (
            <StyledPredictionResultItem key={`item-${index}`}>
              <MainSection>
                <NameSection>{result.name}</NameSection>
                <ResultSection>
                  <PredictionResult
                    position={Position.First}
                    predictionResult={result.pos1}
                  />
                  <PredictionResult
                    position={Position.Second}
                    predictionResult={result.pos2}
                  />
                  <PredictionResult
                    position={Position.Third}
                    predictionResult={result.pos3}
                  />
                  <PredictionResult
                    position={Position.Fourth}
                    predictionResult={result.pos4}
                  />
                  <PredictionResult
                    position={Position.Fifth}
                    predictionResult={result.pos5}
                  />
                  {pointCalculationMethod && pointCalculationMethod !== "Method2025" &&
                    <PredictionResult
                      position={Position.FastestLap}
                      predictionResult={result.fastestLap}
                    />
                  }
                </ResultSection>
              </MainSection>
              <PointsSection>
                <TotalPoints points={result.totalPoints} />
              </PointsSection>
            </StyledPredictionResultItem>
          );
        })}
    </StyledRaceResultOverview>
  );
};
