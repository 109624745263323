import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import Flag from "react-world-flags";
import styled from "styled-components";
import CountdownPill from "./CountdownPill";
import { StopwatchFill } from "react-bootstrap-icons";
import { Driver, race, raceParticipation, racePrediction } from "../Models";

export interface IProps {
  race: race | undefined;
  prediction: racePrediction | undefined;
  participation: raceParticipation | undefined;
  onClickEdit: () => void;
  getDriverByNumber: (driverNumber: number | null) => Driver | undefined;
}

export const WeekendCard = ({
  race,
  prediction,
  participation,
  onClickEdit,
  getDriverByNumber,
}: IProps) => {
  const StyledContainer = styled(Container)`
    margin: 15px 0;
    font-family: "Formula1";
  `;

  const StyledCountDownPill = styled(CountdownPill)`
    font-weight: normal;
    font-size: 24px;
  `;

  const PredictionPart = () => {
    const StyledDriver = styled.span<{ teamColor: string }>`
      padding: 0 8px;
      border-left: 4px solid ${(props) => props.teamColor};
    `;

    const PredictionRow = styled.div`
      font-size: 14px;
      display: grid;
      grid-template-columns: [position] 28px [driver] auto;
    `;

    const DriverName = (props: { driverNumber: number | null }) => {
      const driver = getDriverByNumber(props.driverNumber);
      return (
        <span>
          {driver !== undefined ? (
            <StyledDriver teamColor={driver.team.color}>
              {driver.lastname}
            </StyledDriver>
          ) : (
            <span>Unknown</span>
          )}
        </span>
      );
    };

    const Position = styled.div`
      grid-area: "position";
    `;

    return (
      <>
        {prediction ? (
          <>
            <Card.Title style={{ fontSize: "12px", textAlign: "center" }}>
              Your prediction
            </Card.Title>
            <Card.Text>
              <PredictionRow>
                <Position>1</Position>
                <DriverName driverNumber={prediction.pos1} />
              </PredictionRow>
              <PredictionRow>
                <Position>2</Position>
                <DriverName driverNumber={prediction.pos2} />
              </PredictionRow>
              <PredictionRow>
                <Position>3</Position>
                <DriverName driverNumber={prediction.pos3} />
              </PredictionRow>
              <PredictionRow>
                <Position>4</Position>
                <DriverName driverNumber={prediction.pos4} />
              </PredictionRow>
              <PredictionRow>
                <Position>5</Position>
                <DriverName driverNumber={prediction.pos5} />
              </PredictionRow>
            </Card.Text>
          </>
        ) : (
          <h4>no prediction</h4>
        )}
      </>
    );
  };

  const ParticipantsPart = () => {
    if (
      participation === undefined ||
      participation.participatingUsers.length === 0
    ) {
      return <p>Be the first to predict</p>;
    } else {
      return (
        <>
          {participation?.participatingUsers.map((user: string, index) => {
            return (
              <Badge
                key={`item-${index}`}
                bg="secondary"
                style={{
                  fontWeight: "normal",
                  marginRight: "5px",
                  fontSize: "10px",
                }}
              >
                {user}
              </Badge>
            );
          })}
        </>
      );
    }
  };

  return (
    <div>
      {race === undefined ? (
        <></>
      ) : (
        <StyledContainer>
          <Row className="justify-content-md-center">
            <Col md="auto">
              <Card
                bg={"dark"}
                text={"white"}
                style={{ width: "19rem" }}
                className="mb-2"
              >
                <Card.Header>
                  <Flag code={race?.countryCode} height={18} />
                  {"  "}
                  {race?.name}
                </Card.Header>
                <Card.Body>
                  <PredictionPart />
                  <Row>
                    <Col>
                      <StyledCountDownPill
                        date={race?.raceStart}
                        label=""
                        variant="secondary"
                        completedText="Race started"
                        completedVariant="danger"
                      />
                    </Col>
                    <Col style={{ textAlign: "right" }}>
                      <Button
                        variant="danger"
                        onClick={() => onClickEdit()}
                        size="sm"
                      >
                        Edit
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <ParticipantsPart />
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </StyledContainer>
      )}
    </div>
  );
};
